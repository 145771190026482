.MainButton {
    border-radius: 50;
}

.Section {
    width: 100vw;
    min-height: 80vh;
    /* padding: ; */
    /* height: 85vh; */
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #efefef;
    /* z-index: -100; */
}

.MainButton {
    font-family: 'Ubuntu';
    background-color: #FFAC48;
    box-shadow: 0 3px black;
    border: 2px solid black;
    border-radius: 100;
    padding: 5;
    padding-left: 20;
    margin: 10;
    font-weight: bold;
    size: 14;
}

.MainButton:disabled {
    background-color: gray;
    box-shadow: 0 0px;
}

.MainButton:hover:enabled {
    background-color: #ce852b;
    text-decoration: 2px solid underline black;
}

.MainButton:active {
    /* background-color: red; */
    transform: translateY(4px);
    box-shadow: none;
}


/* 
button:focus {
    outline: none;
    text-decoration: 2px solid underline black;
} */