.Contact {
    width: 100%;
    height: 95vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.DecoratedText {
    font-weight: bold;
    color: #FFAC48;
    text-shadow: 0px 5px black;
    -webkit-text-stroke: 2px black;
}

.CustomInput {
    font-family: 'Ubuntu';
    background-color: white;
    box-shadow: 0 3px black;
    border: 2px solid black;
    border-radius: 100;
    height: 40;
    padding: 5;
    padding-left: 20
}

.CustomInput:focus {
    border: 2px solid black;
    outline: none;
}

.CustomButton {
    font-family: 'Ubuntu';
    background-color: #FFAC48;
    box-shadow: 0 3px black;
    border: 2px solid black;
    border-radius: 100;
    height: 40;
    padding: 5;
    padding-left: 20;
    margin: 10;
    font-weight: bold;
    size: 14;
}

.CustomButton:disabled {
    background-color: gray;
    box-shadow: 0 0px;
}

.CustomButton:hover:enabled {
    background-color: #ce852b;
    text-decoration: 2px solid underline black;
}

.CustomButton:enabled:active {
    /* background-color: red; */
    transform: translateY(4px);
    box-shadow: 0 0px;
}

.CustomButton:focus {
    text-decoration: 2px solid underline black;
}

input:focus {
    outline: none;
}

button:focus {
    outline: none;
}