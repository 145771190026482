.About {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: visible;
    z-index: 100;
    background-color: white;
    min-height: 100vh;
}


/* 
.CustomPaper {
    width: calc(100vw - 50px);
    min-height: 80vh;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
} */

.DecoratedText {
    font-weight: bold;
    color: #FFAC48;
    text-shadow: 0px 5px black;
    -webkit-text-stroke: 2px black;
}