.Approach {
    background-color: #FFAC48;
    width: 100%;
    padding: 100px 0px 50px 0px;
    min-height: calc(80vh - 200px);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.WhiteText {
    font-weight: bold;
    color: white;
    text-shadow: 0px 5px black;
    -webkit-text-stroke: 2px black;
}

.CustomCard {
    /* background-image: url(../../../../assets/images/containers/Artboard_.png`) */
    /* background-size: contain;
    background-repeat: no-repeat;
    background-position: center; */
    /* border: 30px solid black; */
    /* border-image-repeat: round; */
    /* border-image-repeat: round;
    border-image-width: 132.6px 83.2px 41.6px 83.2px;
    border-image-slice: 204 128 64 128 fill; */
    /* border: 100px solid black; */
    width: 100%;
    /* aspect-ratio: 486/563; */
    border: 4px solid black;
    background-color: white;
    border-radius: 25px;
    box-shadow: 0 6px black;
    display: flex;
    flex-direction: column;
}