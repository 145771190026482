.Testimonials {
    background-color: #FFAC48;
    width: 100%;
    padding: 100px 0px 50px 0px;
    min-height: calc(80vh - 200px);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.SpacerTop {
    transform: rotate(0.5turn);
    margin-top: calc(-100vw*300/1920);
    z-index: 100;
}

.SpacerBot {
    margin-bottom: calc(-100vw*300/1920);
    z-index: 100;
}

.WhiteText {
    font-weight: bold;
    color: white;
    text-shadow: 0px 5px black;
    -webkit-text-stroke: 2px black;
}

.TestimonialCard {
    width: 100%;
    /* aspect-ratio: 486/563; */
    border: 4px solid black;
    background-color: white;
    border-radius: 25px;
    box-shadow: 0 6px black;
    display: flex;
    flex-direction: row;

}

.TestimonialMobile {
    width: 90%;
    border: 4px solid black;
    background-color: white;
    border-radius: 25px;
    box-shadow: 0 6px black;
    display: flex;
    flex-direction: column;
}